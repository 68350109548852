








import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'

@Component({ name: 'AgentConfig'})
export default class AgentConfig extends VueBase {
  async goBack() {
    this.$router.push({ name: 'sysInfo' })
  }
  async created() {}
}
